import React from 'react';

function LoginModal({ setID, setPSW, handleLogin, setLoginModalVisible }) {
  return (
    <div className='form'>
      <div className='loginmodalbox' 
      onClick={(e) => { // 백그라운드 클릭 시 모달 창 닫기
        if (e.target.className === 'loginmodalbox') {
          setLoginModalVisible(false);
        }}}>
        <div className='loginmodal'>
          <input name='username' type='text' placeholder='아이디' onChange={(e) => setID(e.target.value)}></input>
          <input name='psw' type='password' placeholder='비밀번호' onChange={(e) => setPSW(e.target.value)}></input>
          <p style={{margin:'5px'}}>
            <input type='submit' value='로그인' onClick={handleLogin}></input>
            <button onClick={() => setLoginModalVisible(false)}>닫기</button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;