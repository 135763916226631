import React, { useRef, useState, useContext } from 'react';
import { DesignContext } from '../../DesignContext.js'

import axios from 'axios';

function Other({ setMasterPage }) {
    // DesignContext에서 제공하는 값들을 가져옵니다
    const { SiteName, setSiteName } = useContext(DesignContext);

    // 버튼->input 버튼 연계 useRef
    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);

    // 서버 전달 데이터 (이미지)
    const [selectedFile, setSelectedFile] = useState(null);

    // 숨겨진 input 버튼 실행
    const handleImageSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // 숨겨진 input 버튼 실행
    const handleImageSelect2 = () => {
        if (fileInputRef2.current) {
            fileInputRef2.current.click();
        }
    };

    // 이미지 파일 임의 뷰 핸들
    const handleImageChange = (e) => {
        const file = e.target.files[0]; // 선택된 파일
        if (file) {
            // 선택된 파일을 상태로 저장
            setSelectedFile(file);
            // 파일을 읽기 위한 FileReader 객체를 생성함
            const reader = new FileReader();
            // 이미지를 Data URL로 변환하기 시작
            reader.readAsDataURL(file);
            // 이미지를 읽고나면 자동으로 호출 하는 콜백 함수
            reader.onloadend = () => {
                const CorsurElement = document.querySelector('img[name="cursor"]');
                if (CorsurElement) {
                    CorsurElement.src = reader.result;
                }
                document.body.style.cursor = `url(${reader.result}) 5 5, auto`;
            };
        }
    };

    // 이미지 파일 임의 뷰 핸들
    const handleImageChange2 = (e) => {
        const file = e.target.files[0]; // 선택된 파일
        if (file) {
            // 선택된 파일을 상태로 저장
            setSelectedFile(file);
            // 파일을 읽기 위한 FileReader 객체를 생성함
            const reader = new FileReader();
            // 이미지를 Data URL로 변환하기 시작
            reader.readAsDataURL(file);
            // 이미지를 읽고나면 자동으로 호출 하는 콜백 함수
            reader.onloadend = () => {
                const SiteIconElement = document.querySelector('img[name="siteicon"]');
                const faviconElement = document.querySelector('link[rel="icon"]');
                if (SiteIconElement) {
                    // `<img>` 요소에 이미지 미리보기 적용
                    SiteIconElement.src = reader.result;
                };
                if (faviconElement) {
                    // 기존 파비콘 업데이트
                    faviconElement.href = reader.result;
                };
            };
        };
    };

    // 사이트 이름 업로드
    const SiteNameUpload = async (SiteName) => {
        try {
            const response = await axios.post(`/data/SiteNameUpload`, { SiteName });
            if (response.data.success) {
                alert(response.data.message)
            } else {
                alert(response.data.message)
            }
        } catch (error) {
            console.error('서버 에러:', error);
            alert('서버에 문제가 발생했습니다.');
        }
    };

    // 이미지 업로드
    const ImageUpload = async (file) => {
        if (file) {
            // 새로운 formData 생성
            const formData = new FormData();
            // FormData에 이미지 파일 추가
            formData.append('CursorImage', file);
            // 서버 전송
            try {
                const response = await axios.post(`/data/CursorUpload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert(response.data.message);
            } catch (error) {
                console.error('서버 에러:', error);
                alert('서버에 문제가 발생했습니다.');
            }
        } else {
            return;
        }
    };

    // 이미지 업로드
    const ImageUpload2 = async (file) => {
        if (file) {
            // 새로운 formData 생성
            const formData = new FormData();
            // FormData에 이미지 파일 추가
            formData.append('FaviconImage', file);
            // 서버 전송
            try {
                const response = await axios.post(`/data/FaviconUpload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert(response.data.message);
            } catch (error) {
                console.error('서버 에러:', error);
                alert('서버에 문제가 발생했습니다.');
            }
        } else {
            return;
        }
    };

    return (<>
        <div className='settingbox'>
            <h3>사이트 이름</h3>
            <input style={{ color: 'black', textAlign:'center' }} type='text' value={SiteName} onChange={(e) => setSiteName(e.target.value)}></input>
            <button onClick={() => SiteNameUpload(SiteName)}>저장</button>

            <h3>사이트 아이콘</h3>
            <img name='siteicon' alt='siteicon' style={{ margin: '5px', width:'50px', height:'50px' }} src={`/images/favicon.png`} />
            {/* 파일 선택 input */}
            <input type="file"
                accept="image/*"
                onChange={handleImageChange2}
                ref={fileInputRef2}
                style={{ display: 'none' }} />
            <button onClick={handleImageSelect2}>이미지변경</button>
            <button onClick={() => ImageUpload2(selectedFile)}>저장</button>

            <h3>마우스 커서</h3>
            <h6 style={{ margin: '0px' }}>* 화면에 표시되는 크기가 그대로 출력됩니다. 이미지 자체 크기를 알맞게 조정해 주세요.</h6>
            <img name='cursor' alt='cursor' style={{ margin: '5px' }} src={`/images/UI/mouth_icon.png`} />
            {/* 파일 선택 input */}
            <input type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: 'none' }} />
            <button onClick={handleImageSelect}>이미지변경</button>
            <button onClick={() => ImageUpload(selectedFile)}>저장</button>

            <button onClick={() => setMasterPage('DesignSet')}>뒤로</button>
        </div>
    </>)
}

export default Other;