import React, { useContext } from 'react';
import { DesignContext } from '../../DesignContext.js'

/*컴포넌트*/
import Setting from '../../Component/Setting/Setting.js'

import Logo from '../../Component/DesingSet/LogoSet.js'
import Background from '../../Component/DesingSet/BackgroundSet.js'
import Ui from '../../Component/DesingSet/UiSet.js'
import Layout from '../../Component/DesingSet/LayoutSet.js'
import SideMenu from '../DesingSet/SideMenuSet.js'
import Font from '../DesingSet/FontSet.js'
import Category from '../DesingSet/CategorySet.js'

import Other from '../OtherSet/Other.js'

function MasterModal({ MasterPage, setMasterModalVisible, setMasterPage, handleLogout, setAuthorAddModalVisible }) {

  // DesignContext에서 제공하는 값들을 가져옵니다
  const { UiDB, SideMenuDB } = useContext(DesignContext);

  let Backgroundstyle;
  if (SideMenuDB.mode === 'IMAGE') {
    Backgroundstyle = {
      backgroundImage: `url(/images/SideMenu/background.png)`,
      backgroundPositionX: `${SideMenuDB.position_x}%`,
      backgroundPositionY: `${SideMenuDB.position_y}%`,
      backgroundSize: `${SideMenuDB.size}%`
    };
  } else {
    Backgroundstyle = { backgroundColor: SideMenuDB.color };
  };

  return (
    <div className='mastermodalbox'
      onClick={(e) => { // 백그라운드 클릭 시 모달 창 닫기
        if (e.target.className === 'mastermodalbox') {
          setMasterModalVisible(false);
        };
      }}>
      <div className='mastermodal' style={Backgroundstyle}>
        <div className='menubox'>
          <div className='menubox-topbottom-box'>
            <div className='ui'>
              <button
                name='back_icon'
                style={{
                  backgroundImage: `url(/images/UI/back_icon.png)`,
                  scale: `${UiDB.back_icon}`
                }}
                onClick={() => setMasterModalVisible(false)}>
              </button>
            </div>
          </div>
          <div className='menubox-buttons-box'>
            {/*첫 번째 페이지 */}
            {MasterPage === 'Main' && (
              <>
                <button onClick={() => setMasterPage('Setting')}>계정 관리</button>
                <button>게시판 설정</button>
                <button onClick={() => setMasterPage('DesignSet')}>디자인 설정</button>
                <button onClick={() => setMasterPage('OtherSet')}>기타 설정</button>
              </>
            )}
            {MasterPage === 'Setting' && (
              <Setting 
                setMasterPage={setMasterPage}
                setAuthorAddModalVisible={setAuthorAddModalVisible}
              />
            )}
            {MasterPage === 'DesignSet' && (
              <>
                <button onClick={() => setMasterPage('background')}>배경</button>
                <button onClick={() => setMasterPage('logo')}>로고</button>
                <button onClick={() => setMasterPage('UI')}>UI</button>
                <button onClick={() => setMasterPage('Category')}>카테고리</button>
                <button onClick={() => setMasterPage('Layout')}>레이아웃</button>
                <button onClick={() => setMasterPage('SideMenu')}>사이드 메뉴</button>
                <button onClick={() => setMasterPage('Font')}>폰트</button>
                <button onClick={() => setMasterPage('Main')}>뒤로</button>
              </>
            )}
            {MasterPage === 'OtherSet' && (
              <Other
                setMasterPage={setMasterPage}
              />
            )}

            {/*세 번째 페이지 */}
            {MasterPage === 'background' && (
              <Background
                setMasterPage={setMasterPage}
              />
            )}
            {MasterPage === 'logo' && (
              <Logo
                setMasterPage={setMasterPage}
              />
            )}
            {MasterPage === 'UI' && (
              <Ui
                setMasterPage={setMasterPage}
              />
            )}
            {MasterPage === 'Category' && (
              <Category
                setMasterPage={setMasterPage}
              />
            )}
            {MasterPage === 'SideMenu' && (
              <SideMenu
                setMasterPage={setMasterPage}
              />
            )}
            {MasterPage === 'Layout' && (
              <Layout
                setMasterPage={setMasterPage}
              />
            )}
            {MasterPage === 'Font' && (
              <Font
                setMasterPage={setMasterPage}
              />
            )}
          </div>
          <div className='menubox-topbottom-box'>
            <div className='ui'>
              <button
                name='logout_icon'
                style={{
                  backgroundImage: `url(/images/UI/logout_icon.png)`,
                  scale: `${UiDB.logout_icon}`
                }}
                onClick={handleLogout}></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterModal;