import axios from 'axios';

// 사이드 메뉴 데이터 가져오기
export const SideMenuData = async (setSideMenuDB) => {
    try {
        const response = await axios.get('/data/SideMenuData');
        const datas = response.data
        if (datas.success) {
            const data = datas.result[0]
            try {
                setSideMenuDB(data);
            } catch (error) {
                console.error('사이드 메뉴 배경 적용 에러:', error);
                alert('사이드 메뉴 배경 적용에 문제가 발생했습니다.');
            };
        };
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    };
};

// 이미지 업로드
export const ImageUpload = async (file) => {
    if (file) {
        // 새로운 formData 생성
        const formData = new FormData();
        // FormData에 이미지 파일 추가
        formData.append('sidemenuImage', file);
        // 서버 전송
        try {
            const response = await axios.post('/data/sidemenuUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert(response.data.message);
        } catch (error) {
            console.error('서버 에러:', error);
            alert('서버에 문제가 발생했습니다.');
        }
    } else {
        return;
    }
};

// 기타 속성 업로드
export const DataUpload = async (SideMenuDB) => {
    try {
        const response = await axios.post('/data/SideMenuSave', { SideMenuDB });
        if (response.data.success) {
            alert(response.data.message)
            console.log('데이터 저장 성공:', response.data);
        } else {
            alert(response.data.message)
            console.error('저장 실패:', response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};