import React, { useContext, useState } from 'react';

import { UserDelete, PswChange } from '../../API/AccountProcess.js'

import { DesignContext } from '../../DesignContext.js'

function Setting({ setMasterPage, setAuthorAddModalVisible }) {

    const { UserList, setUserList } = useContext(DesignContext);

    // on/off
    const [PswChangeVisible, setPswChangeVisible] = useState(false);
    const [UserDeleteVisible, setUserDeleteVisible] = useState(false);

    const [SelectID, setSelectID] = useState('');
    const [PSW, setPSW] = useState('');
    const [PSW2, setPSW2] = useState('');

    return (
        <div className='settingbox'>
            {/* 모달 리스트*/}

            {PswChangeVisible && SelectID && (
                <div className='form'>
                    <div className='loginmodalbox'>
                        <div className='loginmodal'>
                            <input name='selectid' type="text" value={SelectID} readOnly />
                            <input name='psw1' type='password' placeholder='현재 비밀번호' onChange={(e) => setPSW(e.target.value)}></input>
                            <input name='psw2' type='password' placeholder='변경 비밀번호' onChange={(e) => setPSW2(e.target.value)}></input>
                            <input type='submit' value='확인' onClick={()=>{PswChange(SelectID, PSW, PSW2, setPswChangeVisible)}}></input>
                            <button onClick={() => setPswChangeVisible(false)}>닫기</button>
                        </div>
                    </div>
                </div>
            )}

            {UserDeleteVisible && SelectID && (
                <div className='form'>
                    <div className='loginmodalbox'>
                        <div className='loginmodal'>
                            <input name='selectid' type="text" value={SelectID} readOnly />
                            <input name='psw1' type='password' placeholder='확인 비밀번호' onChange={(e) => setPSW(e.target.value)}></input>
                            <input type='submit' value='확인' onClick={()=>UserDelete(SelectID, PSW, setUserList)}></input>
                            <button onClick={() => setUserDeleteVisible(false)}>닫기</button>
                        </div>
                    </div>
                </div>
            )}

            {/* 본문*/}
            <span style={{fontSize:'13px'}}>* admin 계정은 최고관리자 계정으로,</span>
            <span style={{fontSize:'13px'}}> 비밀번호의 수정은 가능하나 삭제 시 페이지가 동작하지 않습니다!</span>
            <h3>계정 목록</h3>
            {UserList.map((users) => (
                <div key={users.id}>
                    <span>ID : {users.username}</span>
                    <div>
                        <button onClick={() => {
                            setPswChangeVisible(true);
                            setSelectID(users.username)
                        }}>비밀번호 변경</button>
                    </div>
                    <div>
                        <button  onClick={() => {
                            setUserDeleteVisible(true);
                            setSelectID(users.username)
                        }}>삭제</button>
                    </div>
                </div>
            ))}
            <button onClick={() => setAuthorAddModalVisible(true)}>관리자 추가</button>
            <button onClick={() => setMasterPage('Main')}>뒤로</button>
        </div>
    );
};

export default Setting;