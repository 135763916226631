import axios from 'axios';

// 로그인 체크 함수
export const loginCheck = async (setLoggedIn) => {
  try {
    const response = await axios.get('/login/loginCheck', { withCredentials: true });
    setLoggedIn(response.data.isLoggedIn);
  } catch (error) {
    console.error('세션 확인 오류:', error);
  }
};

// 로그인 함수
export const handleLogin = async (isId, isPsw, setLoggedIn) => {
  try {
    const response = await axios.post('/login/login', {
      username: isId,
      password: isPsw,
    }, { withCredentials: true });

    if (response.data.success) {
      setLoggedIn(true);
    } else {
      alert(response.data.message);
    }
  } catch (error) {
    console.error('서버 에러:', error);
    alert('서버에 문제가 발생했습니다.');
  }
};

// 로그아웃 함수
export const handleLogout = async (setLoggedIn) => {
  try {
    const response = await axios.post('/login/logout', {}, { withCredentials: true });
    if (response.data.success) {
      alert('로그아웃 성공!');
      setLoggedIn(false);
    } else {
      alert('로그아웃 실패: ' + response.data.message);
    }
  } catch (error) {
    console.error('서버 에러:', error);
    alert('서버에 문제가 발생했습니다.');
  }
};