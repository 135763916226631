import axios from 'axios';

// Ui 스케일 데이터
export const UiData = async (setUiDB) => {
    try {
        const response = await axios.get('/data/UiData', { withCredentials: true });
        if (response.data.success) {
            const results = response.data.result.reduce((acc, { name, value }) => {
                // 객체에 name을 키로, value를 값으로 추가
                acc[name] = value;
                // 누산기 반환
                return acc
            }, {});
            // UiDB 설정
            setUiDB(results);
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    };
};

// 이미지 업로드 함수
export const ImageUpload = async (selectedFiles) => {
    if (selectedFiles && selectedFiles.length > 0) {
        // 새로운 formData 생성
        const formData = new FormData();
        // 배열로 전달된 파일과 이름을 FormData에 추가
        selectedFiles.forEach(({ file, name }) => {
            // FormData에 이미지 파일 추가 (키는 동일하게 'UiImages')
            formData.append('UiImages', file);
            // 각 파일의 이름을 배열 형태로 추가
            formData.append('names[]', name);
        });
        try {
            const response = await axios.post('/data/UiUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('이미지 업로드 성공:', response.data);
            alert(response.data.message);
        } catch (error) {
            console.error('서버 에러:', error);
            alert('서버에 문제가 발생했습니다.');
        }
    } else {
        console.log("선택된 파일이 없습니다.");
        return;
    }
};

// 기타 속성 업로드
export const DataUpload = async (UiDB) => {
    try {
        const response = await axios.post('/data/UiSave', { UiDB });
        if (response.data.success) {
            alert(response.data.message)
            console.log('데이터 저장 성공:', response.data);
        } else {
            alert(response.data.message);
            console.error('저장 실패:', response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};