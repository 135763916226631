import React, { useRef, useState, useContext } from 'react';
import { DesignContext } from '../../DesignContext.js';

/*API */
import { ImageUpload, ImageUpload2, DataUpload } from '../../API/DesignAPI/Layout.js'

function Layout({ setMasterPage }) {

    const { LayoutDB, setLayoutDB, FrameDB, setFrameDB } = useContext(DesignContext);

    // 서버 전달 데이터 (이미지)
    const [selectedFiles, setSelectedFiles] = useState([]); // 초기값을 빈 배열로 설정
    const [selectedSliderFiles, setSelectedSliderFiles] = useState([]);

    // 버튼->input 버튼 연계 useRef
    const fileInputRef = useRef(null);

    // 숨겨진 input 버튼 실행
    const handleImageSelect = (frameNumber) => {
        if (fileInputRef.current) {
            fileInputRef.current.setAttribute('data-frame-number', frameNumber); // frame 번호를 속성에 저장
            fileInputRef.current.click();
        };
    };

    // 이미지 파일 임의 뷰 핸들
    const handleImageChange = (e) => {
        const file = e.target.files[0]; // 선택된 파일
        const frameNumber = e.target.getAttribute('data-frame-number'); // 저장된 frame 번호 가져오기
        if (file && frameNumber) {
            setSelectedFiles((prev) => {
                const updatedFiles = Array.isArray(prev) ? prev : [];
                const newFiles = [
                    ...updatedFiles.filter((f) => f.frameNumber !== frameNumber),
                    { file, frameNumber },
                ];
                return newFiles;
            });
            // 파일을 읽기 위한 FileReader 객체를 생성
            const reader = new FileReader();
            // 이미지를 Data URL로 변환
            reader.readAsDataURL(file);
            // 이미지를 읽고 나면 자동으로 호출되는 콜백
            reader.onloadend = () => {
                const imageUrl = reader.result;
                // DOM을 이용해 해당 프레임에 임시 뷰 적용
                const frameElement = document.querySelector(`div[name="${frameNumber}"]`);
                if (frameElement) {
                    frameElement.style.backgroundImage = `url(${imageUrl})`;
                };
            };
        }
    };

    // 파일 선택 후 상태 업데이트
    const handleFileSelect = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedSliderFiles(prevState => [
                ...prevState,
                { file: file, frameNumber: index }
            ]);
        };
    };

    // DB 업로드
    const handleUploadDatas = () => {
        if (selectedFiles) {
            ImageUpload(selectedFiles);
        }
        if (selectedSliderFiles) {
            ImageUpload2(selectedSliderFiles);
        }
        DataUpload(LayoutDB, FrameDB);
    };

    return (
        <div className='settingbox'>
            <h3>레이아웃 설정</h3>
            <p>컬러 값 : {LayoutDB.color}</p>
            {/* 색상 뷰 박스, 클릭 시 colorPicker 클릭 */}
            <div
                onClick={() => document.getElementById('colorPicker').click()}
                style={{
                    width: '100px',
                    height: '20px',
                    backgroundColor: LayoutDB.color,
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    marginTop: '8px',
                    cursor: 'pointer'
                }}
            />
            {/* 색상 추출 */}
            <input
                type="color"
                id="colorPicker"
                style={{ display: 'none' }}
                value={LayoutDB.color}
                onChange={(e) => {
                    const newColorValue = e.target.value;
                    setLayoutDB((prevState) => ({
                        ...prevState,
                        color: newColorValue
                    }));
                }}
            />
            <p>투명도</p>
            <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={LayoutDB.opacity}
                onChange={(e) => {
                    const newOpacity = e.target.value;
                    setLayoutDB((prevState) => ({
                        ...prevState,
                        opacity: newOpacity
                    }))
                }}
            />
            <label>
                <input
                    type="radio"
                    name="LayoutType"
                    value="layout1"
                    checked={LayoutDB.layout === 'layout1'}
                    onChange={(e) => {
                        const newMode = e.target.value;
                        setLayoutDB((prevState) => ({
                            ...prevState,
                            layout: newMode
                        }));
                    }}
                />
                레이아웃 1
                <input
                    type="radio"
                    name="LayoutType"
                    value="layout2"
                    checked={LayoutDB.layout === 'layout2'}
                    onChange={(e) => {
                        const newMode = e.target.value;
                        setLayoutDB((prevState) => ({
                            ...prevState,
                            layout: newMode
                        }));
                    }}
                />
                레이아웃 2
                <input
                    type="radio"
                    name="LayoutType"
                    value="layout3"
                    checked={LayoutDB.layout === 'layout3'}
                    onChange={(e) => {
                        const newMode = e.target.value;
                        setLayoutDB((prevState) => ({
                            ...prevState,
                            layout: newMode
                        }));
                    }}
                />
                레이아웃 3
            </label>
            {LayoutDB.layout === 'layout1' && (
                <>
                    <h4>1번 레이아웃</h4>
                    1번 이미지
                    {/* 첫 번째 버튼: 첫 번째 이미지를 선택 */}
                    <input
                        style={{ textAlign: 'center' }}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileSelect(e, 1)}
                    />
                    2번 이미지
                    {/* 두 번째 버튼: 두 번째 이미지를 선택 */}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileSelect(e, 2)}
                    />
                    3번 이미지
                    {/* 세 번째 버튼: 세 번째 이미지를 선택 */}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileSelect(e, 3)}
                    />
                    {FrameDB.filter((frame) => frame.number !== 1) // number가 1이 아닌 항목만 필터링
                        .map((frame) => (
                            <div key={frame.number}>
                                <h4>{frame.number}번 레이아웃</h4>
                                {/* 모드 선택 라디오 버튼 */}
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name={`backgroundType-${frame.number}`}
                                            checked={frame.image}
                                            onChange={(e) => {
                                                const NewValue = e.target.checked; // 새로 변경된 값
                                                // 상태 업데이트: 해당 number를 가진 객체만 업데이트
                                                setFrameDB((prevState) =>
                                                    prevState.map((item) =>
                                                        item.number === frame.number
                                                            ? { ...item, image: NewValue } // number가 일치하는 항목만 업데이트
                                                            : item
                                                    )
                                                );
                                            }}
                                        />
                                        이미지
                                    </label>
                                </div>
                                {frame.image === true && (
                                    <>
                                        <div>
                                            {/* 파일 선택 input */}
                                            <input type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                ref={fileInputRef}
                                                style={{ display: 'none' }} />
                                        </div>
                                        <div><button onClick={() => handleImageSelect(frame.number)}>이미지변경</button></div>
                                        <div>
                                            <p>상/하</p>
                                            <input
                                                type="range"
                                                min="-100"
                                                max="100"
                                                step="1"
                                                value={frame.position_y}
                                                onChange={(e) => {
                                                    const newPositionY = e.target.value;
                                                    // 상태 업데이트: 해당 number를 가진 객체만 업데이트
                                                    setFrameDB((prevState) =>
                                                        prevState.map((item) =>
                                                            item.number === frame.number
                                                                ? { ...item, position_y: newPositionY } // number가 일치하는 항목만 업데이트
                                                                : item
                                                        )
                                                    );
                                                }}
                                            />
                                            <p>좌/우</p>
                                            <input
                                                type="range"
                                                min="-100"
                                                max="100"
                                                step="1"
                                                value={frame.position_x}
                                                onChange={(e) => {
                                                    const newPositionX = e.target.value;
                                                    // 상태 업데이트: 해당 number를 가진 객체만 업데이트
                                                    setFrameDB((prevState) =>
                                                        prevState.map((item) =>
                                                            item.number === frame.number
                                                                ? { ...item, position_x: newPositionX } // number가 일치하는 항목만 업데이트
                                                                : item
                                                        )
                                                    );
                                                }}
                                            />
                                            <p>Zoom</p>
                                            <input
                                                type="range"
                                                min="0"
                                                max="200"
                                                step="1"
                                                value={frame.scale}
                                                onChange={(e) => {
                                                    const newScale = e.target.value;
                                                    // 상태 업데이트: 해당 number를 가진 객체만 업데이트
                                                    setFrameDB((prevState) =>
                                                        prevState.map((item) =>
                                                            item.number === frame.number
                                                                ? { ...item, scale: newScale } // number가 일치하는 항목만 업데이트
                                                                : item
                                                        )
                                                    );
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                </>
            )}
            {LayoutDB.layout === 'layout2' && (
                <>
                <p>준비 중 입니다 ;_;!!</p>
                </>
            )}
            {LayoutDB.layout === 'layout3' && (
                <>
                <p>준비 중 입니다 ;_;!!</p>
                </>
            )}
            <button onClick={handleUploadDatas}>저장</button>
            <button onClick={() => setMasterPage('DesignSet')}>뒤로</button>
        </div>
    )
};

export default Layout;