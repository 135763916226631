import React, { useRef, useState, useEffect, useContext } from 'react';
import { DesignContext } from '../../DesignContext.js'

/*API */
import { ImageUpload, DataUpload } from '../../API/DesignAPI/Ui.js'

function Ui({ setMasterPage }) {

    // DesignContext에서 제공하는 값들을 가져옵니다
    const { UiDB, setUiDB } = useContext(DesignContext);

    // 서버 전달 데이터: 여러 파일을 담을 수 있는 배열로 관리
    const [selectedFiles, setSelectedFiles] = useState([]);

    // 아이콘 정보 배열
    const icons = [
        { name: 'menu_icon', label: '메뉴', defaultImage: '/images/UI/menu_icon.png' },
        { name: 'music_icon', label: '음악', defaultImage: '/images/UI/music_icon.png' },
        { name: 'back_icon', label: '뒤로가기', defaultImage: '/images/UI/back_icon.png' },
        { name: 'logout_icon', label: '로그아웃', defaultImage: '/images/UI/logout_icon.png' }
    ];

    // 각 아이콘에 대한 파일 입력 참조 객체 생성
    const fileInputRefs = {
        menu_icon: useRef(null),
        music_icon: useRef(null),
        back_icon: useRef(null),
        logout_icon: useRef(null)
    };

    // 첫 렌더링 시 로컬 스토리지 확인 후 selectedFiles 설정
    useEffect(() => {
        const storedFiles = JSON.parse(localStorage.getItem('selectedFiles'));
        if (storedFiles) {
            // 로컬 스토리지에서 불러온 데이터를 File 객체로 변환
            const convertedFiles = storedFiles.map((storedFile) => ({
                name: storedFile.name,
                file: dataURLtoFile(storedFile.dataURL, storedFile.name + '.png'),
                dataURL: storedFile.dataURL // 로컬 스토리지에서 불러온 Data URL 저장
            }));
            setSelectedFiles(convertedFiles);
        }
    }, []);

    const dataURLtoFile = (dataURL, filename) => {
        const arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]), len = bstr.length, u8arr = new Uint8Array(len);
        let n = len;
        while (n--) u8arr[n] = bstr.charCodeAt(n);
        return new File([u8arr], filename, { type: mime });
    };

    // 상태가 변경될 때마다 로컬 스토리지에 selectedFiles 업데이트
    useEffect(() => {
        if (selectedFiles.length > 0) {
            const filesToStore = selectedFiles.map(({ name, file }) => {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onloadend = () => {
                        resolve({
                            name,
                            dataURL: reader.result
                        });
                    };
                    reader.readAsDataURL(file);
                });
            });
            // 모든 파일이 Data URL로 변환될 때까지 기다림
            Promise.all(filesToStore).then((storedFiles) => {
                localStorage.setItem('selectedFiles', JSON.stringify(storedFiles));
            });
        }
    }, [selectedFiles]);

    // 이미지 파일 선택 및 미리 보기 설정
    const handleImageChange = (e, name) => {
        const file = e.target.files[0];
        if (file) {
            // 파일을 읽기 위한 FileReader 객체를 생성함
            const reader = new FileReader();
            // 이미지를 Data URL로 변환하기 시작
            reader.readAsDataURL(file);
            // 이미지를 읽고나면 자동으로 호출 하는 콜백 함수
            reader.onloadend = () => {
                const uiElement = document.querySelector(`.uiSet[name="${name}"]`);
                const buttonElement = document.querySelector(`.ui button[name="${name}"]`);
                if (uiElement) {
                    uiElement.style.backgroundImage = `url(${reader.result})`;
                    buttonElement.style.backgroundImage = `url(${reader.result})`;
                };
            };
            // 선택한 파일을 배열에 추가
            setSelectedFiles((prevFiles) => {
                // 같은 이름의 파일 찾기
                const existingFileIndex = prevFiles.findIndex((item) => item.name === name);
                // 새로운 파일 객체 생성
                const newFile = { name, file };
                // 만약 같은 이름의 파일이 이미 있으면
                if (existingFileIndex > -1) {
                    // 기존 배열을 복사하여 업데이트
                    const updatedFiles = [...prevFiles];
                    // 기존 파일을 새 파일로 교체
                    updatedFiles[existingFileIndex] = newFile;
                    // 업데이트된 배열을 반환
                    return updatedFiles;
                }
                // 같은 이름의 파일이 없다면 새 파일을 배열에 추가
                return [...prevFiles, newFile];
            });
        };
    };

    // 로컬 스토리지에서 해당 name의 이미지를 찾기
    const getBackgroundImage = (name, defaultImage) => {
        const storedFile = selectedFiles.find((file) => file.name === name);
        return storedFile ? storedFile.dataURL : defaultImage;
    };

    // 각 name에 맞는 숨겨진 input 버튼 실행
    const handleImageSelect = (name) => {
        if (fileInputRefs[name].current) {
            fileInputRefs[name].current.click();
        }
    };

    // DB 업로드
    const handleUploadDatas = () => {
        ImageUpload(selectedFiles); // selectedFiles 배열을 ImageUpload 함수에 전달
        DataUpload(UiDB);
    };

    return (
        <div className='settingbox'>
            <h3>UI 설정</h3>
            {icons.map((icon) => (
                <div className='uiSetSub' key={icon.name}>
                    {/* UI 이름 */}
                    <label>{icon.label}</label>
                    {/* UI 칸, 클릭 시  input 활성화*/}
                    <div className='ui'
                        onClick={() => handleImageSelect(icon.name)}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e, icon.name)}
                            ref={fileInputRefs[icon.name]} // 개별 참조 사용
                            style={{ display: 'none' }}
                        />
                        {/* UI 이미지 뷰 */}
                        <div className='uiSet' name={icon.name} 
                        style={{ transform: `scale(${UiDB[icon.name]})`, 
                        backgroundImage: `url(${getBackgroundImage(icon.name, icon.defaultImage)})` }}></div>
                    </div>
                    {/* 스케일 값 조정 */}
                    <input
                        type="range"
                        min="0"
                        max="2"
                        step="0.01"
                        value={UiDB[icon.name]}
                        onChange={(e) => {
                            const newScaleValue = e.target.value;
                            setUiDB((prevState) => ({
                                ...prevState,
                                [icon.name]: newScaleValue
                            }));
                        }}
                    />
                </div>
            ))}
            <button onClick={handleUploadDatas}>저장</button>
            <button style={{ marginTop: '0px' }} onClick={() => setMasterPage('DesignSet')}>뒤로</button>
        </div>
    );
};


export default Ui;