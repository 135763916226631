import React, { useRef, useState, useEffect, useContext } from 'react';
import { DesignContext } from '../../DesignContext.js';

/*API */
import { ImageUpload, DataUpload } from '../../API/DesignAPI/Logo.js'

function Logo({ setMasterPage }) {
    // DesignContext에서 제공하는 값들을 가져옵니다
    const { LogoDB, setLogoDB } = useContext(DesignContext);

    // 버튼->input 버튼 연계 useRef
    const fileInputRef = useRef(null);

    // 서버 전달 데이터 (이미지)
    const [selectedFile, setSelectedFile] = useState(null);

    // 첫 렌더링 시 로컬 스토리지 확인 후 LogoDB 설정
    useEffect(() => {
        // 로컬 스토리지에서 이미지 데이터 확인 후 selectedFile 설정
        const storedImage = localStorage.getItem('selectedFile');
        if (storedImage) {
            const file = dataURLtoFile(storedImage, 'image.png'); // Data URL을 File 객체로 변환
            setSelectedFile(file);
        };
    }, []);

    const dataURLtoFile = (dataURL, filename) => {
        const arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]), len = bstr.length, u8arr = new Uint8Array(len);
        let n = len; // 'n'을 'let'으로 변경
        while (n--) u8arr[n] = bstr.charCodeAt(n);
        return new File([u8arr], filename, { type: mime });
    };

    // 상태가 변경될 때마다 로컬 스토리지에 업데이트
    useEffect(() => {
        // selectedFile이 있을 경우 로컬 스토리지에 Data URL로 저장
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const dataURL = reader.result;
                localStorage.setItem('selectedFile', dataURL); // Data URL을 로컬 스토리지에 저장
            };
            reader.readAsDataURL(selectedFile);
        };
    }, [selectedFile]);

    // 숨겨진 input 버튼 실행
    const handleImageSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        };
    };

    // 이미지 파일 임의 뷰 핸들
    const handleImageChange = (e) => {
        const file = e.target.files[0]; // 선택된 파일
        if (file) {
            // 선택된 파일을 상태로 저장
            setSelectedFile(file);
            // 파일을 읽기 위한 FileReader 객체를 생성함
            const reader = new FileReader();
            // 이미지를 Data URL로 변환하기 시작
            reader.readAsDataURL(file);
            // 이미지를 읽고 나면 자동으로 호출하는 콜백 함수
            reader.onloadend = () => {
                // 영역 Element 
                const LogoElement = document.querySelector(`.logo img`);
                const imageUrl = reader.result;
                LogoElement.src = imageUrl
            };
        };
    };

    // DB 업로드
    const handleUploadDatas = () => {
        ImageUpload(selectedFile);
        DataUpload(LogoDB);
    };

    return (
        <div className='settingbox'>
            <h3>로고 설정</h3>
            {/* 파일 선택 input */}
            <input type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: 'none' }} />
            <button onClick={handleImageSelect}>이미지변경</button>
            {/* 세부 설정 input */}
            <p>상/하</p>
            <input
                type="range"
                min="-100"
                max="100"
                step="1"
                value={LogoDB.position_y}
                onChange={(e) => {
                    const newPositionY = e.target.value;
                    setLogoDB((prevState) => ({
                        ...prevState,
                        position_y: newPositionY
                    }));
                }}
            />
            <p>좌/우</p>
            <input
                type="range"
                min="-100"
                max="100"
                step="1"
                value={LogoDB.position_x}
                onChange={(e) => {
                    const newPositionX = e.target.value;
                    setLogoDB((prevState) => ({
                        ...prevState,
                        position_x: newPositionX
                    }));
                }}
            />
            <p>Zoom</p>
            <input
                type="range"
                min="0"
                max="2"
                step="0.01"
                value={LogoDB.scale}
                onChange={(e) => {
                    const newScaleValue = e.target.value;
                    setLogoDB((prevState) => ({
                        ...prevState,
                        scale: newScaleValue
                    }));
                }}
            />
            <button onClick={handleUploadDatas}>저장</button>
            <button onClick={() => setMasterPage('DesignSet')}>뒤로</button>
        </div>
    );
};

export default Logo;