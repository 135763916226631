import React, { createContext, useState, useEffect } from 'react';

import axios from 'axios';

import { GetUserList } from './API/AccountProcess.js'

import { BackgroundData } from './API/DesignAPI/Background.js';
import { LogoData } from './API/DesignAPI/Logo.js';
import { UiData } from './API/DesignAPI/Ui.js'
import { CategoryData } from './API/DesignAPI/Category.js'
import { SideMenuData } from './API/DesignAPI/SideMenu.js'
import { LayoutData } from './API/DesignAPI/Layout.js'

// Context 생성
export const DesignContext = createContext();

// Context Provider 컴포넌트
export const DesignProvider = ({ children }) => {

  // 임시
  useEffect(() => {
    // sitename.txt 파일에서 제목 가져오기
    axios
      .get(`/textfiles/sitename.txt`)
      .then((response) => {
        setSiteName(response.data) // 브라우저 탭 제목 설정
      })
      .catch((error) => {
        console.error("sitename.txt를 불러오는데 실패했습니다:", error);
      });
  }, []);

  // 계정 관리 값
  const [UserList, setUserList] = useState([]);

  // 디자인 값
  const [BackgroundDB, setBackgroundDB] = useState({});
  const [LogoDB, setLogoDB] = useState({});
  const [UiDB, setUiDB] = useState({});
  const [CategoryList, setCategoryList] = useState({});
  const [CategoryDB, setCategoryDB] = useState({});
  const [SideMenuDB, setSideMenuDB] = useState({});
  const [LayoutDB, setLayoutDB] = useState({});
  const [FrameDB, setFrameDB] = useState([]);

  // 기타 정보 값
  const [SiteName, setSiteName] = useState({});

  // 계정 정보 가져오기
  useEffect(() => {
    GetUserList(setUserList);
  }, []);

  // 디자인 정보 가져오기
  useEffect(() => {
    BackgroundData(setBackgroundDB);
    LogoData(setLogoDB);
    UiData(setUiDB);
    CategoryData(setCategoryList, setCategoryDB);
    SideMenuData(setSideMenuDB);
    LayoutData(setLayoutDB, setFrameDB);
  }, []);

  // 기타 정보 설정하기
  useEffect(() => {
    if (SiteName) {
      document.title = SiteName;
    };
    document.body.style.cursor = "url('/images/UI/mouth_icon.png') 5 5, auto";
  }, [SiteName]);

  // 데이터가 아직 로드되지 않았을 때 로딩 상태 표시
  if (Object.keys(BackgroundDB).length === 0 || Object.keys(LogoDB).length === 0 || Object.keys(UiDB).length === 0 ||
    Object.keys(CategoryList).length === 0 || Object.keys(CategoryDB).length === 0 || Object.keys(SideMenuDB).length === 0 ||
    Object.keys(LayoutDB).length === 0 || Object.keys(FrameDB).length === 0) {
    return <div>Loading...</div>;
  };

  return (
    <DesignContext.Provider value={{
      UserList, setUserList,
      BackgroundDB, setBackgroundDB,
      LogoDB, setLogoDB,
      UiDB, setUiDB,
      CategoryList, setCategoryList,
      CategoryDB, setCategoryDB,
      SideMenuDB, setSideMenuDB,
      LayoutDB, setLayoutDB,
      FrameDB, setFrameDB,
      SiteName, setSiteName
    }}>
      {children}
    </DesignContext.Provider>
  );
};