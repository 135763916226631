import React, { useRef, useState, useEffect, useContext } from 'react';
import { DesignContext } from '../../DesignContext.js';

/*API */
import { ImageUpload, DataUpload } from '../../API/DesignAPI/Category.js'

function Category({ setMasterPage }) {
    // DesignContext에서 제공하는 값들을 가져옵니다
    const { CategoryDB, setCategoryDB } = useContext(DesignContext);

    // 서버 전달 데이터 (이미지)
    const [selectedFile, setSelectedFile] = useState(null);

    // 버튼->input 버튼 연계 useRef
    const fileInputRef = useRef(null);

    // 숨겨진 input 버튼 실행
    const handleImageSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // 첫 렌더링 시 로컬 스토리지 확인 후 CategoryDB 설정
    useEffect(() => {
        // 로컬 스토리지에서 이미지 데이터 확인 후 selectedFile 설정
        const storedImage = localStorage.getItem('selectedFile');
        if (storedImage) {
            const file = dataURLtoFile(storedImage, 'image.png'); // Data URL을 File 객체로 변환
            setSelectedFile(file);
        };
    }, []);

    const dataURLtoFile = (dataURL, filename) => {
        const arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]), len = bstr.length, u8arr = new Uint8Array(len);
        let n = len; // 'n'을 'let'으로 변경
        while (n--) u8arr[n] = bstr.charCodeAt(n);
        return new File([u8arr], filename, { type: mime });
    };

    // 상태가 변경될 때마다 로컬 스토리지에 업데이트
    useEffect(() => {
        // selectedFile이 있을 경우 로컬 스토리지에 Data URL로 저장
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const dataURL = reader.result;
                localStorage.setItem('selectedFile', dataURL); // Data URL을 로컬 스토리지에 저장
            };
            reader.readAsDataURL(selectedFile);
        }
    }, [CategoryDB, selectedFile]);


    // 이미지 파일 임의 뷰 핸들
    const handleImageChange = (e) => {
        const file = e.target.files[0]; // 선택된 파일
        if (file) {
            // 선택된 파일을 상태로 저장
            setSelectedFile(file);
            // 파일을 읽기 위한 FileReader 객체를 생성함
            const reader = new FileReader();
            // 이미지를 Data URL로 변환하기 시작
            reader.readAsDataURL(file);
            // 이미지를 읽고나면 자동으로 호출 하는 콜백 함수
            reader.onloadend = () => {
                // 영역 Element
                const categoryElements = document.querySelectorAll('.side button');
                categoryElements.forEach(button => {
                    button.style.backgroundImage = `url(${reader.result})`;
                });
            };
        }
    };

    // DB 업로드
    const handleUploadDatas = () => {
        ImageUpload(selectedFile);
        DataUpload(CategoryDB);
    };

    return (
        <div className='settingbox'>
            <h3>카테고리 설정</h3>
            <label>
                <input
                    type="checkbox"
                    checked={CategoryDB.background}
                    onChange={(e) => {
                        const NewValue = e.target.checked
                        setCategoryDB((prevState) => ({
                            ...prevState,
                            background: NewValue
                        }));
                    }}
                />배경 이미지
            </label>
            {CategoryDB.background && (
                <>
                    {/* 파일 선택 input */}
                    <input type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }} />
                    <button onClick={handleImageSelect}>이미지변경</button>
                    <p>높이 길이</p>
                    <input
                        type="range"
                        min="1"
                        max="300"
                        value={CategoryDB.height}
                        onChange={(e) => {
                            const newHeight = e.target.value;
                            setCategoryDB((prevState) => ({
                                ...prevState,
                                height: newHeight
                            }));
                        }}
                    />
                    <p>확대/축소</p>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={CategoryDB.size}
                        onChange={(e) => {
                            const newSize = e.target.value;
                            setCategoryDB((prevState) => ({
                                ...prevState,
                                size: newSize
                            }));
                        }}
                    />
                    <p>좌/우</p>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={CategoryDB.position_x}
                        onChange={(e) => {
                            const newPosition_x = e.target.value;
                            setCategoryDB((prevState) => ({
                                ...prevState,
                                position_x: newPosition_x
                            }))
                        }}
                    />
                    <p>위/아래</p>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={CategoryDB.position_y}
                        onChange={(e) => {
                            const newPosition_y = e.target.value;
                            setCategoryDB((prevState) => ({
                                ...prevState,
                                position_y: newPosition_y
                            }))
                        }}
                    />
                </>
            )}
            <label>
                <input
                    type="checkbox"
                    checked={CategoryDB.font}
                    onChange={(e) => {
                        const NewValue = e.target.checked
                        setCategoryDB((prevState) => ({
                            ...prevState,
                            font: NewValue
                        }));
                    }}
                />내장 폰트
            </label>
            {CategoryDB.font && (
                <>
                    <p>폰트 크기</p>
                    <input
                        type="range"
                        min="1"
                        max="100"
                        value={CategoryDB.font_size}
                        onChange={(e) => {
                            const newSize = e.target.value;
                            setCategoryDB((prevState) => ({
                                ...prevState,
                                font_size: newSize
                            }));
                        }}
                    />
                </>
            )}
            <button onClick={handleUploadDatas}>저장</button>
            <button onClick={() => setMasterPage('DesignSet')}>뒤로</button>
        </div>
    );
};

export default Category;