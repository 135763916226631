import React from 'react';

function AuthorAddModal({ setID, setPSW, setAdminPSW, handleAuthorAdd, setAuthorAddModalVisible }) {
  return (
    <div className='form'>
      <div className='loginmodalbox'>
        <div className='loginmodal'>
          <input type='text' name='username' placeholder='아이디' onChange={event => setID(event.target.value)}></input>
          <input type='password' name='psw' placeholder='비밀번호' onChange={event => setPSW(event.target.value)}></input>
          <input type='password' name='psw' placeholder='관리자 비밀번호' onChange={event => setAdminPSW(event.target.value)}></input>
          <p>
            <input type='submit' value='등록' onClick={handleAuthorAdd}></input>
            <button onClick={() => setAuthorAddModalVisible(false)}>닫기</button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AuthorAddModal;