import React, { useRef, useState, useEffect } from 'react';

/*API */
import { FontSetData, FontList, FontView, FontUpload, FontSave, FontDelete } from '../../API/DesignAPI/Font.js'

function Font({ setMasterPage }) {
    const fileInputRef = useRef(null);

    // 디자인 값
    const [FontDB, setFontDB] = useState(null)
    const [Fonts, setFonts] = useState([]);

    // 서버 전달 데이터
    const [selectedFiles, setSelectedFiles] = useState([]);

    // 첫 렌더링 시 로컬 스토리지 확인 후 FontDB 설정, FontList 불러오기
    useEffect(() => {
        const storedFontDB = JSON.parse(localStorage.getItem('FontDB'));
        if (storedFontDB) {
            setFontDB(storedFontDB); // 로컬 스토리지에서 값을 가져옴
        } else {
            FontSetData('ALL', setFontDB); // 로컬에 값이 없으면 서버에서 데이터 가져옴
        }
        FontList(setFonts)
    }, []);

    // 상태가 변경될 때마다 로컬 스토리지에 업데이트
    useEffect(() => {
        if (FontDB !== null) {
            localStorage.setItem('FontDB', JSON.stringify(FontDB)); // 로컬 스토리지에 상태 저장
        }
    }, [FontDB]);

    // 폰트 임의 뷰
    useEffect(() => {
        if (FontDB) {
            FontView(FontDB.FontSelect)
            document.documentElement.style.setProperty('--page-color', FontDB.FontColor);
        }
    }, [FontDB, Fonts]); // FontDB와 Fonts가 변경될 때마다 실행

    // 숨겨진 input 버튼 실행
    const handleFontSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // 폰트 파일 추가
    const handleFileAdd = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(files);
    };

    // 폰트 업로드 이후 리스트 업데이트
    useEffect(() => {
        if (selectedFiles.length > 0) {
            FontUpload(selectedFiles).then(() => {
                // 업로드 후 FontList 호출하여 목록 갱신
                FontList(setFonts);
            });
        }
    }, [selectedFiles]);

    // DB 업로드
    const handleFontSave = () => {
        FontSave(FontDB.FontSelect, 'ALL', FontDB.FontColor)
    }

    // 폰트 삭제
    const handelFontDelete = (FontName) => {
        FontDelete(FontName).then(() => {
            FontList(setFonts);
        });
    }

    if (FontDB === null) {
        return (
            <h3>Loading...</h3>
        )
    }

    return (
        <div className='settingbox'>
            <h3>폰트 설정</h3>
            {/* 컬러 값 */}
            <p>컬러 값 : {FontDB.FontColor}</p>
            {/* 폰트 컬러 뷰, 클릭 시 colorPicker 클릭 */}
            <div
                onClick={() => document.getElementById('colorPicker').click()}
                style={{
                    width: '100px',
                    height: '20px',
                    backgroundColor: FontDB.FontColor,
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    marginTop: '8px',
                    cursor: 'pointer'
                }} />
            {/* colorPicker */}
            <input
                type="color"
                id="colorPicker"
                style={{ display: 'none' }}
                value={FontDB.FontColor}
                onChange={(e) => {
                    const newColorValue = e.target.value;
                    setFontDB((prevState) => ({
                        ...prevState,
                        FontColor: newColorValue
                    }));
                }
                }
            />
            {/* 폰트 파일 선택 input */}
            <input type="file"
                accept=".ttf,.woff,.woff2"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileAdd}
                multiple />
            {/* input연동 클릭 버튼 */}
            <button onClick={handleFontSelect}>폰트 추가</button>
            <h6 style={{margin:'1px 0px 0px 0px'}}>*ttf, woff, woff2 파일을 지원합니다.</h6>
            <h6 style={{margin:'1px 0px 0px 0px'}}>*폰트 파일 이름에 띄어쓰기, 괄호 값이 들어가는 경우 제대로 된 인식이 불가능합니다.</h6>
            {/* 폰트 파일 리스트 */}
            {Fonts.map((font, index) => (
                <div>
                    <label key={index}>
                        <input
                            type="radio"
                            name="font"
                            value={font.name}
                            checked={FontDB.FontSelect === font.name}
                            onChange={(e) => {
                                const newFontStyle = e.target.value;
                                setFontDB((prevState) => ({
                                    ...prevState,
                                    FontSelect: newFontStyle
                                }));
                            }}
                        />
                        {font.name}
                    </label>
                    {/* 폰트 파일 삭제 */}
                    <button onClick={() => handelFontDelete(font.name)}>삭제</button>
                </div>
            ))}
            <button onClick={handleFontSave}>저장</button>
            <button onClick={() => setMasterPage('DesignSet')}>뒤로</button>
        </div>
    )
};

export default Font;