import axios from 'axios';

// 백그라운드 배경 설정 정보 가져오기
export const BackgroundData = async (setBackgroundDB) => {
    try {
        const response = await axios.get('/data/BackgroundData');
        if (response.data.success) {
            const data = response.data.result[0]
            // 불러온 값 배치, 2차원 배열로 전송되기 때문에 첫번째 값 출력
            setBackgroundDB(data);
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};

// 이미지 업로드
export const ImageUpload = async (file) => {
    if (file) {
        // 새로운 formData 생성
        const formData = new FormData();
        // FormData에 이미지 파일 추가
        formData.append('backgroundImage', file);
        // 서버 전송
        try {
            const response = await axios.post('/data/BackgroundUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert(response.data.message);
        } catch (error) {
            console.error('서버 에러:', error);
            alert('서버에 문제가 발생했습니다.');
        }
    } else {
        return;
    }
};


// 기타 속성 업로드
export const DataUpload = async (BackgroundDB) => {
    try {
        const response = await axios.post('/data/BackgroundSave', { BackgroundDB });
        if (response.data.success) {
            alert(response.data.message)
            console.log('데이터 저장 성공:', response.data);
        } else {
            alert(response.data.message)
            console.error('저장 실패:', response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};