import React, { useRef, useState, useEffect, useContext } from 'react';
import { DesignContext } from '../../DesignContext.js';

/*API */
import { DataUpload, ImageUpload } from '../../API/DesignAPI/SideMenu.js'

function SideMenu({ setMasterPage }) {

    // DesignContext에서 제공하는 값들을 가져옵니다
    const { SideMenuDB, setSideMenuDB } = useContext(DesignContext);

    // 서버 전달 데이터 (이미지)
    const [selectedFile, setSelectedFile] = useState(null);

    // 첫 렌더링 시 로컬 스토리지 확인 후 LogoDB 설정
    useEffect(() => {
        // 로컬 스토리지에서 이미지 데이터 확인 후 selectedFile 설정
        const storedImage = localStorage.getItem('selectedFile');
        if (storedImage) {
            const file = dataURLtoFile(storedImage, 'image.png'); // Data URL을 File 객체로 변환
            setSelectedFile(file);
        };
    }, []);

    const dataURLtoFile = (dataURL, filename) => {
        const arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]), len = bstr.length, u8arr = new Uint8Array(len);
        let n = len; // 'n'을 'let'으로 변경
        while (n--) u8arr[n] = bstr.charCodeAt(n);
        return new File([u8arr], filename, { type: mime });
    };

    // 상태가 변경될 때마다 로컬 스토리지에 업데이트
    useEffect(() => {
        // selectedFile이 있을 경우 로컬 스토리지에 Data URL로 저장
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const dataURL = reader.result;
                localStorage.setItem('selectedFile', dataURL); // Data URL을 로컬 스토리지에 저장
            };
            reader.readAsDataURL(selectedFile);
        };
    }, [selectedFile]);

    // 버튼->input 버튼 연계 useRef
    const fileInputRef = useRef(null);

    // 숨겨진 input 버튼 실행
    const handleImageSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // 이미지 파일 임의 뷰 핸들
    const handleImageChange = (e) => {
        const file = e.target.files[0]; // 선택된 파일
        if (file) {
            // 선택된 파일을 상태로 저장
            setSelectedFile(file);
            // 파일을 읽기 위한 FileReader 객체를 생성함
            const reader = new FileReader();
            // 이미지를 Data URL로 변환하기 시작
            reader.readAsDataURL(file);
            // 이미지를 읽고나면 자동으로 호출 하는 콜백 함수
            reader.onloadend = () => {
                const SideMenuElement = document.querySelector('.mastermodal');
                SideMenuElement.style.backgroundImage = `url(${reader.result})`; // 읽어온 파일의 Data URL을 배경 이미지로 설정
            };
        }
    };

    // DB 업로드
    const handleUploadDatas = () => {
        ImageUpload(selectedFile);
        DataUpload(SideMenuDB);
    };

    return (
        <div className='settingbox'>
            <h3>사이드 메뉴 설정</h3>
            <h3>배경</h3>
            <label>
                <input
                    type="radio"
                    name="backgroundType"
                    value="IMAGE"
                    checked={SideMenuDB.mode === 'IMAGE'}
                    onChange={(e) => {
                        const newMode = e.target.value;
                        setSideMenuDB((prevState) => ({
                            ...prevState,
                            mode: newMode
                        }))
                    }}
                />
                이미지
            </label>
            <label>
                <input
                    type="radio"
                    name="backgroundType"
                    value="CSS"
                    checked={SideMenuDB.mode === 'CSS'}
                    onChange={(e) => {
                        const newMode = e.target.value;
                        setSideMenuDB((prevState) => ({
                            ...prevState,
                            mode: newMode
                        }))
                    }}
                />
                컬러
            </label>
            {/* 배경이 이미지 모드 일 때 */}
            {SideMenuDB.mode === 'IMAGE' && (
                <>
                    {/* 파일 선택 input */}
                    <input type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }} />
                    <button onClick={handleImageSelect}>이미지변경</button>
                    {/* 세부 조정 input */}
                    <p>확대/축소</p>
                    <input
                        type="range"
                        min="0"
                        max="500"
                        value={SideMenuDB.size}
                        onChange={(e) => {
                            const newSize = e.target.value;
                            setSideMenuDB((prevState) => ({
                                ...prevState,
                                size: newSize
                            }))
                        }}
                    />
                    <p>좌/우</p>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={SideMenuDB.position_x}
                        onChange={(e) => {
                            const newPosition_x = e.target.value;
                            setSideMenuDB((prevState) => ({
                                ...prevState,
                                position_x: newPosition_x
                            }))
                        }}
                    />
                    <p>위/아래</p>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={SideMenuDB.position_y}
                        onChange={(e) => {
                            const newPosition_y = e.target.value;
                            setSideMenuDB((prevState) => ({
                                ...prevState,
                                position_y: newPosition_y
                            }))
                        }}
                    />
                </>
            )}
            {/* 배경이 컬러 모드 일 때 */}
            {SideMenuDB.mode === 'CSS' && (
                <>
                    <p>컬러 값 : {SideMenuDB.color}</p>
                    {/* 색상 뷰 박스, 클릭 시 colorPicker 클릭 */}
                    <div
                        onClick={() => document.getElementById('colorPicker').click()}
                        style={{
                            width: '100px',
                            height: '20px',
                            backgroundColor: SideMenuDB.color,
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            marginTop: '8px',
                            cursor: 'pointer'
                        }}
                    />
                    {/* 색상 추출 */}
                    <input
                        type="color"
                        id="colorPicker"
                        style={{ display: 'none' }}
                        value={SideMenuDB.color}
                        onChange={(e) => {
                            const newColorValue = e.target.value;
                            setSideMenuDB((prevState) => ({
                                ...prevState,
                                color: newColorValue
                            }))
                        }}
                    />
                </>
            )}
            <button onClick={handleUploadDatas}>저장</button>
            <button onClick={() => setMasterPage('DesignSet')}>뒤로</button>
        </div>
    );
};

export default SideMenu;