import React, { useRef, useState, useEffect, useContext } from 'react';
import { DesignContext } from '../../DesignContext.js';

/*API */
import { ImageUpload, DataUpload } from '../../API/DesignAPI/Background.js'

function Background({ setMasterPage }) {
  // DesignContext에서 제공하는 값들을 가져옵니다
  const { BackgroundDB, setBackgroundDB } = useContext(DesignContext);

  // 버튼->input 버튼 연계 useRef
  const fileInputRef = useRef(null);

  // 서버 전달 데이터 (이미지)
  const [selectedFile, setSelectedFile] = useState(null);

  // 첫 렌더링 시 로컬 스토리지 확인
  useEffect(() => {
    // 로컬 스토리지에서 이미지 데이터 확인 후 selectedFile 설정
    const storedImage = localStorage.getItem('selectedFile');
    if (storedImage) {
      const file = dataURLtoFile(storedImage, 'image.png'); // Data URL을 File 객체로 변환
      setSelectedFile(file);
    };
  }, []);

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]), len = bstr.length, u8arr = new Uint8Array(len);
    let n = len; // 'n'을 'let'으로 변경
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
  };

  // 상태가 변경될 때마다 로컬 스토리지에 업데이트
  useEffect(() => {
    // selectedFile이 있을 경우 로컬 스토리지에 Data URL로 저장
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result;
        localStorage.setItem('selectedFile', dataURL); // Data URL을 로컬 스토리지에 저장
      };
      reader.readAsDataURL(selectedFile);
    };
  }, [selectedFile]);

  // 이미지 파일 임의 뷰 핸들
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // 선택된 파일
    if (file) {
      // 선택된 파일을 상태로 저장
      setSelectedFile(file);
      // 파일을 읽기 위한 FileReader 객체를 생성함
      const reader = new FileReader();
      // 이미지를 Data URL로 변환하기 시작
      reader.readAsDataURL(file);
      // 이미지를 읽고나면 자동으로 호출 하는 콜백 함수
      reader.onloadend = () => {
        const backgroundElement = document.querySelector('.background')
        backgroundElement.style.backgroundImage = `url(${reader.result})`; // 읽어온 파일의 Data URL을 배경 이미지로 설정
      };
    } else {
      alert('선택된 파일이 존재하지 않습니다.')
    };
  };

  // 숨겨진 input 버튼 실행
  const handleImageSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // DB 업로드
  const handleUploadDatas = () => {
    ImageUpload(selectedFile);
    DataUpload(BackgroundDB);
  };

  return (
    <>
      <div className='settingbox'>
        <h3>배경 설정</h3>
        {/* 모드 선택 라디오 버튼 */}
        <label>
          <input
            type="radio"
            name="backgroundType"
            value="IMAGE"
            checked={BackgroundDB.mode === 'IMAGE'}
            onChange={(e) => {
              const newModeValue = e.target.value;
              setBackgroundDB((prevState) => ({
                ...prevState,
                mode: newModeValue
              }));
            }}
          />
          이미지
        </label>
        <label>
          <input
            type="radio"
            name="backgroundType"
            value="CSS"
            checked={BackgroundDB.mode === 'CSS'}
            onChange={(e) => {
              const newModeValue = e.target.value;
              setBackgroundDB((prevState) => ({
                ...prevState,
                mode: newModeValue
              }));
            }}
          />
          컬러
        </label>
        {/* 이미지 모드일 경우 메뉴 */}
        {BackgroundDB.mode === 'IMAGE' && (
          <>
            {/* 파일 선택 input */}
            <input type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: 'none' }} />
            <button onClick={handleImageSelect}>이미지변경</button>
            {/* 세부 설정 */}
            <p>Blur</p>
            <input
              type="range"
              min="0"
              max="100"
              value={BackgroundDB.blur}
              onChange={(e) => {
                const newBlurValue = e.target.value;
                setBackgroundDB((prevState) => ({
                  ...prevState,
                  blur: newBlurValue
                }));
              }}
            />
            <p>Position</p>
            <input
              type="range"
              min="0"
              max="100"
              value={BackgroundDB.position_y}
              onChange={(e) => {
                const newPosition_y = e.target.value;
                setBackgroundDB((prevState) => ({
                  ...prevState,
                  position_y: newPosition_y
                }));
              }}
            />
            <p>Zoom</p>
            <input
              type="range"
              min="100"
              max="500"
              step="1"
              value={BackgroundDB.scale}
              onChange={(e) => {
                const newScaleValue = e.target.value;
                setBackgroundDB((prevState) => ({
                  ...prevState,
                  scale: newScaleValue
                }));
              }}
            />
          </>
        )}
        {/* CSS 모드일 경우 메뉴 */}
        {BackgroundDB.mode === 'CSS' && (
          <>
            <p>컬러 값 : {BackgroundDB.BackgroundColor}</p>
            {/* 색상 뷰 박스, 클릭 시 colorPicker 클릭 */}
            <div
              onClick={() => document.getElementById('colorPicker').click()}
              style={{
                width: '100px',
                height: '20px',
                backgroundColor: BackgroundDB.BackgroundColor,
                border: '1px solid #ccc',
                borderRadius: '4px',
                marginTop: '8px',
                cursor: 'pointer'
              }}
            />
            {/* 색상 추출 */}
            <input
              type="color"
              id="colorPicker"
              style={{ display: 'none' }}
              value={BackgroundDB.BackgroundColor}
              onChange={(e) => {
                const newColorValue = e.target.value;
                setBackgroundDB((prevState) => ({
                  ...prevState,
                  BackgroundColor: newColorValue
                }))
              }
              }
            />
          </>
        )}
        <button style={{ marginTop: '0px' }} onClick={handleUploadDatas}>저장</button>
        <button style={{ marginTop: '0px' }} onClick={() => setMasterPage('DesignSet')}>뒤로</button>
      </div>
    </>
  )
};

export default Background;