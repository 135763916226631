import axios from 'axios';

export const LayoutData = async (setLayoutDB, setFrameDB) => {
    try {
        // 레이아웃 종류 가져오기
        const response = await axios.get('/data/LayoutData');
        if (response.data.success) {
            const datas = response.data;
            try {
                // 공통 데이터 (레이아웃 종류, 컬러, 투명도, 좌/우, 상/하)
                setLayoutDB({
                    color: datas.DefaultResult[0].color,
                    opacity: datas.DefaultResult[0].opacity,
                    layout: datas.Layout,
                });
                // 레이아웃에 따른 프레임 데이터
                const newFrameDB = datas.MainBoxResult.map((item) => ({
                    number: item.number,
                    image: item.mode === "IMAGE",
                    position_x: item.position_x,
                    position_y: item.position_y,
                    scale: item.scale,
                  }));
                  setFrameDB(newFrameDB);
            } catch (error) {
                console.error('레이아웃 데이터 저장 에러:', error);
                alert('레이아웃 데이터 저장에 문제가 발생했습니다.', error);
            };
        } else {
            console.error('응답 데이터에 문제가 있습니다.', response.data.message);
        };
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    };
};

export const ImageUpload = async (selectedFiles) => {
    if (selectedFiles && selectedFiles.length > 0) {
        // 새로운 formData 생성
        const formData = new FormData();
        // 배열로 전달된 파일과 frameNumber를 FormData에 추가
        selectedFiles.forEach(({ file, frameNumber }) => {
            // FormData에 이미지 파일 추가 (키는 'LayoutImages')
            formData.append('LayoutImages', file);
            // 각 파일의 frameNumber를 배열 형태로 추가
            formData.append('frameNumbers[]', frameNumber);
        });
        try {
            const response = await axios.post('/data/LayoutUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('이미지 업로드 성공:', response.data);
            alert(response.data.message);
        } catch (error) {

        }
    } else {
        return;
    }
};

export const ImageUpload2 = async (selectedSliderFiles) => {
    if (selectedSliderFiles && selectedSliderFiles.length > 0) {
        // 새로운 formData 생성
        const formData = new FormData();
        // 배열로 전달된 파일과 frameNumber를 FormData에 추가
        selectedSliderFiles.forEach(({ file, frameNumber }) => {
            // FormData에 이미지 파일 추가 (키는 'LayoutSliderImages')
            formData.append('LayoutSliderImages', file);
            // 각 파일의 frameNumber를 배열 형태로 추가
            formData.append('frameNumbers[]', frameNumber);
        });
        try {
            const response = await axios.post('/data/LayoutUpload2', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('이미지 업로드 성공:', response.data);
            alert(response.data.message);
        } catch (error) {
            console.error('서버 에러:', error);
            alert('서버에 문제가 발생했습니다.');
        }
    } else {
        return;
    }
};

// 기타 속성 업로드
export const DataUpload = async (LayoutDB, FrameDB) => {
    try {
        const response = await axios.post('/data/LayoutSave', { LayoutDB, FrameDB });
        if (response.data.success) {
            alert(response.data.message)
            console.log('데이터 저장 성공:', response.data);
        } else {
            alert(response.data.message);
            console.error('저장 실패:', response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};