import React, { useContext, useEffect, useState, useMemo } from 'react';
import axios from 'axios';

/* CSS */
import './App.css';
import './CSS/ui.css';
import './CSS/modals.css';
import './CSS/sidemenu.css';
import './CSS/animation.css';

/*API*/
import { loginCheck, handleLogin, handleLogout } from './API/LoginProcess.js';

import { GetUserList } from './API/AccountProcess.js'

import { FontDefaultView } from './API/DesignAPI/Font.js'

/*컴포넌트 */
import LoginModal from './Component/Modal/LoginModal.js';
import MasterModal from './Component/Modal/MasterModal';
import AuthorAddModal from './Component/Modal/AuthorAddModal';

/*Context */
import { DesignProvider, DesignContext } from './DesignContext.js';

// Context 효과 적용
const App = () => {
  return (
    <DesignProvider>
      <MainComponent />
    </DesignProvider>
  );
};

const MainComponent = () => {
  // 로그인 관리 State
  const [LoggedIn, setLoggedIn] = useState(false);

  // 모달 창 관리 State
  const [LoginModalVisible, setLoginModalVisible] = useState(false);
  const [MasterModalVisible, setMasterModalVisible] = useState(false);
  const [AuthorAddModalVisible, setAuthorAddModalVisible] = useState(false);
  const [ChatModalVisible, setChatModalVisible] = useState(false);

  // 관리자 페이지, 초깃값 Main
  const [MasterPage, setMasterPage] = useState('Main');

  // ID, PASSWORD 입력란
  const [ID, setID] = useState('');
  const [PSW, setPSW] = useState('');
  const [AdminPSW, setAdminPSW] = useState('');

  // 로그인 체크
  useEffect(() => {
    loginCheck(setLoggedIn);
  }, []);

  // 첫 렌더링 시 디자인 정보 가져오기
  const { BackgroundDB, LogoDB, UiDB, CategoryList, CategoryDB, LayoutDB, FrameDB, setUserList } = useContext(DesignContext);

  const r = parseInt(LayoutDB.color.slice(1, 3), 16);
  const g = parseInt(LayoutDB.color.slice(3, 5), 16);
  const b = parseInt(LayoutDB.color.slice(5, 7), 16);

  // 첫 렌더링 시 디자인 정보 가져오기
  useEffect(() => {
    FontDefaultView('ALL')
  }, []);

  // 페이지가 새로고침될 때 모든 로컬 스토리지 삭제
  useEffect(() => {
    localStorage.clear();
  }, []);

  const images = useMemo(
    () => [
      `/images/Slider/1.png`, // 첫 번째 이미지 경로
      `/images/Slider/2.png`, // 두 번째 이미지 경로
      `/images/Slider/3.png`, // 세 번째 이미지 경로
    ],
    []
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // 3초마다 슬라이드 변경
    return () => clearInterval(interval);
  }, [images]);

  const handleAuthorAdd = async () => {
    try {
      const response = await axios.post('/login/AuthorAdd', {
        username: ID,
        password: PSW,
        Apassword: AdminPSW
      });
      if (response.data.success) {
        alert('등록되었습니다.');
        setAuthorAddModalVisible(false);
        GetUserList(setUserList)
      } else {
        alert('등록에 실패했습니다.');
      }
    } catch (error) {
      console.error('서버 에러:', error);
      alert('서버에 문제가 발생했습니다.');
    }
  };

  // 데이터가 아직 로드되지 않았을 때 로딩 상태 표시
  if (!BackgroundDB || !LogoDB) {
    return <div>Loading...</div>;
  }

  // 본문 HTML
  return (
    <>
      {/*모달 리스트 */}
      {LoginModalVisible && !LoggedIn && (
        <LoginModal
          setID={setID}
          setPSW={setPSW}
          handleLogin={() => handleLogin(ID, PSW, setLoggedIn)}
          setLoginModalVisible={setLoginModalVisible}
        />
      )}

      {MasterModalVisible && LoggedIn && (
        <MasterModal
          MasterPage={MasterPage}
          setMasterPage={setMasterPage}
          setMasterModalVisible={setMasterModalVisible}
          handleLogout={() => handleLogout(setLoggedIn)}
          setAuthorAddModalVisible={setAuthorAddModalVisible}
        />
      )}

      {AuthorAddModalVisible && LoggedIn && (
        <AuthorAddModal
          setID={setID}
          setPSW={setPSW}
          setAdminPSW={setAdminPSW}
          handleAuthorAdd={handleAuthorAdd}
          setAuthorAddModalVisible={setAuthorAddModalVisible}
        />
      )}

      {ChatModalVisible && (
        <div className='chatmodalbox'>
          <div className='chatmodal'>
            <div className='chatbox'>
              <div className='chatlist_left'>
                <div className='chatpicture'></div>
                <div className='chatlog'></div>
              </div>
              <div className='chatlist_right'>
                <div className='chatpicture'></div>
                <div className='chatlog'></div>
              </div>
            </div>
            <div className='editbox'></div>
            <div className='buttonbox'>
              <div className='ui'>
                <button
                  name='back_icon'
                  style={{
                    backgroundImage: `url(/images/UI/back_icon.png)`,
                    scale: `${UiDB.back_icon}`
                  }}
                  onClick={() => setChatModalVisible(false)}>
                </button>
              </div>
              <div className='ui'>
                <button
                  name='send_icon'
                  style={{
                    backgroundImage: `url(/images/Chat/send_icon.png)`
                  }}>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 배경화면 */}
      {BackgroundDB && BackgroundDB.mode === 'IMAGE' && (
        <>
          <div className='background'
            style={{
              backgroundImage: `url('/images/Background/background.png')`,
              backgroundPositionY: `${BackgroundDB.position_y}%`,
              scale: `${BackgroundDB.scale}%`
            }}
          />
          <div className='blur-overlay'
            style={{ backdropFilter: `blur(${BackgroundDB.blur}px)` }} />
        </>
      )}

      {BackgroundDB && BackgroundDB.mode === 'CSS' && (
        <div className='background'
          style={{ backgroundColor: `${BackgroundDB.BackgroundColor}` }}
        />
      )}

      {/* 실질적인 컨테이너 */}
      <div className='maincontainer'>
        <div className='header'>
          <div className='ui'>
            <button
              name='menu_icon'
              style={{
                backgroundImage: `url(/images/UI/menu_icon.png)`,
                scale: `${UiDB.menu_icon}`
              }}
              onClick={() => {
                if (LoggedIn) {
                  setMasterModalVisible(true);
                  setLoginModalVisible(false);
                } else {
                  setLoginModalVisible(true);
                  setMasterModalVisible(false);
                }
              }}>
            </button>
          </div>
          <div className='ui'>
            <button
              name='music_icon'
              style={{
                backgroundImage: `url(/images/UI/music_icon.png)`,
                scale: `${UiDB.music_icon}`
              }}>
            </button>
          </div>
        </div>
        <div className='logo'>
          <img
            src={`/images/Logo/background.png`}
            alt='logo'
            style={{ scale: `${LogoDB.scale}`, transform: `translate(${LogoDB.position_x}%, ${LogoDB.position_y}%)` }}
          />
        </div>

        <div className='main'>

          <div className='first'>
            <div name='1'
              style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, ${LayoutDB.opacity}` }}>
              <div className="slider">
                {images.map((image, index) => {
                  let className = "slide";
                  if (index === currentIndex) className += " active";
                  else if (index === (currentIndex - 1 + images.length) % images.length) className += " prev";
                  else if (index === (currentIndex + 1) % images.length) className += " next";
                  return (
                    <div
                      key={index}
                      className={className}
                      style={{ backgroundImage: `url(${image})` }}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <div className='second'>
            <div name='2'
              style={{
                backgroundColor: `rgba(${r}, ${g}, ${b}, ${LayoutDB.opacity}`,
                backgroundImage: FrameDB[1].image ? `url(/images/Layout/background/2.png)` : '',
                backgroundPositionX: FrameDB[1].image ? `${FrameDB[1].position_x}%` : '',
                backgroundPositionY: FrameDB[1].image ? `${FrameDB[1].position_y}%` : '',
                backgroundSize: FrameDB[1].image ? `${FrameDB[1].scale}%` : ''
              }}>
              <div style={{ whiteSpace: "pre-line" }}>
                {`공지사항

                디자인 배포를 위한 임시 공지란 입니다.
                추후 게시판 기능과 연동될 예정입니다.
                슬라이드형 제작 또한 고려중이나,
                이는 개발 기간이 조금 더 필요할 가능성이 높습니다.

                -꽉찬강정-`}
              </div>
            </div>
            <div name='3'
              style={{
                backgroundColor: `rgba(${r}, ${g}, ${b}, ${LayoutDB.opacity}`,
                backgroundImage: FrameDB[2].image ? `url(/images/Layout/background/3.png)` : '',
                backgroundPositionX: FrameDB[2].image ? `${FrameDB[2].position_x}%` : '',
                backgroundPositionY: FrameDB[2].image ? `${FrameDB[2].position_y}%` : '',
                backgroundSize: FrameDB[2].image ? `${FrameDB[2].scale}%` : ''
              }}>
              {/*<button onClick={() => setChatModalVisible(true)}>핸드폰 채팅</button>*/}
              <p style={{ width: '60%', fontSize: '25px' }}>D-DAY ♥ 100</p>
              {/*<button>캐릭터 소개</button>*/}
            </div>
          </div>

          <div className='third'>
            <div name='4'
              style={{
                backgroundColor: `rgba(${r}, ${g}, ${b}, ${LayoutDB.opacity}`,
                backgroundImage: FrameDB[3].image ? `url(/images/Layout/background/4.png)` : '',
                backgroundPositionX: FrameDB[3].image ? `${FrameDB[3].position_x}%` : '',
                backgroundPositionY: FrameDB[3].image ? `${FrameDB[3].position_y}%` : '',
                backgroundSize: FrameDB[3].image ? `${FrameDB[3].scale}%` : ''
              }} />
            <div name='5'
              style={{
                backgroundColor: `rgba(${r}, ${g}, ${b}, ${LayoutDB.opacity}`,
                backgroundImage: FrameDB[4].image ? `url(/images/Layout/background/5.png)` : '',
                backgroundPositionX: FrameDB[4].image ? `${FrameDB[4].position_x}%` : '',
                backgroundPositionY: FrameDB[4].image ? `${FrameDB[4].position_y}%` : '',
                backgroundSize: FrameDB[4].image ? `${FrameDB[4].scale}%` : ''
              }} />
            <div name='6'
              style={{
                backgroundColor: `rgba(${r}, ${g}, ${b}, ${LayoutDB.opacity}`,
                backgroundImage: FrameDB[5].image ? `url(/images/Layout/background/6.png)` : '',
                backgroundPositionX: FrameDB[5].image ? `${FrameDB[5].position_x}%` : '',
                backgroundPositionY: FrameDB[5].image ? `${FrameDB[5].position_y}%` : '',
                backgroundSize: FrameDB[5].image ? `${FrameDB[5].scale}%` : ''
              }}>
              <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                새로운 글의 업데이트를 나타내는 창 입니다.
              </a>
              <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                추후 게시판과 연동되며,
              </a>
              <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                클릭 시 해당 게시글로 이동합니다.
              </a>
            </div>
          </div>

        </div>

        <div className='side'>

          {CategoryDB.background === true && CategoryDB.font === true &&
            (Object.entries(CategoryList).map(([key, value]) => (
              <button key={key}
                style={{
                  height: `${CategoryDB.height}px`,
                  backgroundImage: `url(/images/Category/background.png)`,
                  backgroundPositionX: `${CategoryDB.position_x}%`,
                  backgroundPositionY: `${CategoryDB.position_y}%`,
                  backgroundSize: `${CategoryDB.size}%`
                }}>
                <span
                  style={{ fontSize: `${CategoryDB.font_size}px` }}>{value}</span>
              </button>
            )))}

          {CategoryDB.background === true && CategoryDB.font === false &&
            (Object.entries(CategoryList).map(([key, value]) => (
              <button key={key}
                style={{
                  height: `${CategoryDB.height}px`,
                  backgroundImage: `url(/images/Category/background.png)`,
                  backgroundPositionX: `${CategoryDB.position_x}%`,
                  backgroundPositionY: `${CategoryDB.position_y}%`,
                  backgroundSize: `${CategoryDB.size}%`
                }}>
              </button>
            )))}

          {CategoryDB.font === false && CategoryDB.font === true &&
            (Object.entries(CategoryList).map(([key, value]) => (
              <button key={key}
                style={{
                  height: `${CategoryDB.height}px`
                }}>
                <span
                  style={{ fontSize: `${CategoryDB.font_size}px` }}>{value}</span>
              </button>
            )))}

        </div>
        <div className='footer'>
          <p>Create by 꽉찬강정</p>
          <p style={{ margin: `5px 0px` }}>Version: Test - Desing</p>
        </div>
      </div>
    </>
  );
};

export default App;
