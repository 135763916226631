import axios from 'axios';

// 페이지 첫 랜더링 시 배경화면 불러오기
export const LogoData = async (setLogoDB) => {
    try {
        // BackgroundData로 데이터 불러오기
        const response = await axios.get('/data/LogoData');
        // 성공적으로 데이터를 불러왔을 시
        if (response.data.success) {
            // json 형식이기 때문에 첫번째 배열 값을 추출
            const data = response.data.result[0] || {}
            //LogoDB에 셋
            try {
                setLogoDB(data)
            } catch (error) {
                console.error('로고 데이터 적용 에러:', error);
                alert('로고 데이터 적용에 문제가 발생했습니다.');
            }
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};

// 이미지 업로드
export const ImageUpload = async (file) => {
    if (file) {
        // 새로운 formData 생성
        const formData = new FormData();
        // FormData에 이미지 파일 추가
        formData.append('logoImage', file);
        // 서버 전송
        try {
            const response = await axios.post('/data/LogoUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert(response.data.message);
        } catch (error) {
            console.error('서버 에러:', error);
            alert('서버에 문제가 발생했습니다.');
        }
    } else {
        return;
    }
};

// 기타 속성 업로드
export const DataUpload = async (LogoDB) => {
    try {
        const response = await axios.post('/data/LogoSave', { LogoDB });
        if (response.data.success) {
            alert(response.data.message)
            console.log('데이터 저장 성공:', response.data);
        } else {
            alert(response.data.message)
            console.error('데이터 저장 실패:', response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};