import axios from 'axios';

export const FontDefaultView = async (place) => {
    try {
        // 서버에서 폰트 경로와 색상 값 가져오기
        const response = await axios.get('/data/FontDefaultView', {
            params: { place }
        });

        if (response.data.success) {
            const { defaultFont, fontPath, default_color } = response.data;
            // 서버에서 받은 폰트 경로를 사용하여 FontFace 객체 생성
            const fontFace = new FontFace(defaultFont, `url(${fontPath})`);
            // 폰트를 로드하고 로드된 폰트를 문서에 추가
            fontFace.load().then(() => {
                // 로드된 폰트를 문서에 추가
                document.fonts.add(fontFace);
                // 폰트 및 색상 적용
                document.documentElement.style.setProperty('--default-font-family', `${defaultFont}, sans-serif`);
                document.documentElement.style.setProperty('--page-color', default_color);
            }).catch(error => {
                console.error('폰트 로드 실패:', error);
                alert('폰트를 로드할 수 없습니다.');
            });
        } else {
            alert(response.data.message);
        };
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    };
};

// 영역별(palce) 폰트 데이터
export const FontSetData = async (place, setFontDB) => {
    try {
        const response = await axios.get('/data/FontDefaultView', {
            params: { place }
        });
        if (response.data.success) {
            const default_font = response.data.defaultFont;
            const default_color = response.data.default_color;
            const results = {}
            if (typeof default_font && typeof default_color) {
                results.FontSelect = default_font;
                results.FontColor = default_color;
                setFontDB(results);
            } else {
                alert('유효하지 않은 폰트 & 색상 값 입니다.')
                console.error('유효하지 않은 폰트 & 색상 값:', default_font, default_color);
            }
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};

export const FontView = async (FontSelect) => {
    try {
        // 서버에서 폰트 경로와 색상 값 가져오기
        const response = await axios.get('/data/FontView', {
            params: { FontSelect }
        });

        if (response.data.success) {
            const { fontPath } = response.data;
            // 서버에서 받은 폰트 경로를 사용하여 FontFace 객체 생성
            const fontFace = new FontFace(FontSelect, `url(${fontPath})`);
            // 폰트를 로드하고 로드된 폰트를 문서에 추가
            fontFace.load().then(() => {
                // 로드된 폰트를 문서에 추가
                document.fonts.add(fontFace);
                // 폰트 적용
                document.documentElement.style.setProperty('--default-font-family', `${FontSelect}, sans-serif`);
            }).catch(error => {
                console.error('폰트 로드 실패:', error);
                alert('폰트를 로드할 수 없습니다.');
            });
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};

// 보유 폰트 리스트
export const FontList = async (setFonts) => {
    try {
        const response = await axios.get('/data/FontList');
        if (response.data.success) {
            const font_list = response.data.fonts; // 폰트 리스트
            // 폰트 리스트가 존재하는 경우 설정
            if (font_list) {
                setFonts(font_list);
            } else {
                console.error('폰트 리스트 불러오기 실패:', response.data.message);
            }
        } else {
            alert('폰트 리스트 불러오기 실패: ' + response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};

// 폰트 업로드
export const FontUpload = async (selectedFiles) => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
        formData.append('fonts', file);
    });
    try {
        const response = await axios.post('/data/FontUpload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log('폰트 업로드 성공:', response.data);
        alert(response.data.message)
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};

// 폰트 정보 DB 업로드
export const FontSave = async (selectedFont, place, FontColor) => {
    try {
        const response = await axios.post('/data/FontSave', { selectedFont, place, FontColor });
        console.log('폰트 저장 성공:', response.data);
        alert(response.data.message)
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
};

// 폰트 삭제
export const FontDelete = async (fontName) => {
    try {
        const response = await axios.post('/data/FontDelete', { fontName });
        if (response.data.success) {
            console.log('폰트 삭제 성공:', response.data);
            alert(response.data.message)
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    }
}