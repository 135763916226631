import axios from 'axios';

// 디자인 설정 시 보낼 데이터
export const CategoryData = async (setCategoryList, setCategoryDB) => {
    try {
        const response = await axios.get('/data/CategoryData');
        if (response.data.success) {
            const datas = response.data
            // 사이즈와 메뉴 리스트 먼저 투척
            setCategoryList(datas.FontData)
            setCategoryDB({
                height: datas.SizeData.height
            });
            // 배경 모드가 켜져 있을 때
            if (datas.BackgroundData) {
                setCategoryDB((prevState) => ({
                    ...prevState,
                    background: true,
                    position_x: datas.BackgroundData.position_x,
                    position_y: datas.BackgroundData.position_y,
                    size: datas.BackgroundData.size
                }));
            };
            // 폰트가 켜져 있을 때
            if (datas.Font) {
                setCategoryDB((prevState) => ({
                    ...prevState,
                    font: true,
                    font_size: datas.FontSizeData.size
                }));
            // 폰트가 켜져 있지 않을 때
            } else {
                setCategoryDB((prevState) => ({
                    ...prevState,
                    font: false
                }));
            };
        } else {
            alert(response.data.message);
        };
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    };
};

// 이미지 업로드
export const ImageUpload = async (file) => {
    if (file) {
        // 새로운 formData 생성
        const formData = new FormData();
        // FormData에 이미지 파일 추가
        formData.append('categoryImage', file);
        // 서버 전송
        try {
            const response = await axios.post('/data/CategoryUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert(response.data.message);
        } catch (error) {
            console.error('서버 에러:', error);
            alert('서버에 문제가 발생했습니다.');
        }
    } else {
        return;
    }
};

// 기타 속성 업로드
export const DataUpload = async (CategoryDB) => {
    try {
        const response = await axios.post('/data/CategorySave', { CategoryDB });
        if (response.data.success) {
            alert(response.data.message)
            console.log('데이터 저장 성공:', response.data);
        } else {
            alert(response.data.message)
            console.error('저장 실패:', response.data.message);
        }
    } catch (error) {
        console.error('서버 에러:', error);
        alert('서버에 문제가 발생했습니다.');
    };
};