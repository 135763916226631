import axios from 'axios';

// 모든 유저 리스트 가져오기
export const GetUserList = async (setUserList) => {
    try {
        const response = await axios.get('/account/UserList', { withCredentials: true });
        if (response.data.success) {
            const datas = response.data;
            setUserList(datas.result);
        } else {
            alert(response.data.message)
        };
    } catch (error) {
        console.error('서버 오류:', error);
    };
};

// 비밀번호 변경
export const PswChange = async (ID, PSW, PSW2, setPswChangeVisible) => {
    try {
        const response = await axios.post('/account/UserPswChange', { ID, PSW, PSW2 });
        if (response.data.success) {
            alert(response.data.message);
            setPswChangeVisible(false)
        } else {
            alert(response.data.message)
        };
    } catch (error) {
        console.error('서버 오류:', error);
    };
}

// 유저 삭제
export const UserDelete = async (ID, PSW, setUserList) => {
    try {
        const response = await axios.post('/account/UserDelete', { ID, PSW });
        if (response.data.success) {
            alert(response.data.message);
            GetUserList(setUserList);
        } else {
            alert(response.data.message)
        };
    } catch (error) {
        console.error('서버 오류:', error);
    };
}